<template>
  <div>
    <z-progress :g_show="first_show"/>
    <iframe
        :src="'https://money.yandex.ru/quickpay/shop-widget?writer=seller&targets=SEWA '+steamID+'&targets-hint=&default-sum=500&button-text=11&payment-type-choice=on&mobile-payment-type-choice=on&comment=on&hint=%D0%BC%D0%BE%D0%B6%D0%B5%D1%82%D0%B5%20%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%B8%D1%82%D1%8C%20%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%90%D0%B4%D0%BC%D0%B8%D0%BD%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8%20Terranova&successURL=https%3A%2F%2Fmy.spaceengineers.world%2Ffinance%2Fuser&quickpay=shop&account=410015909334277'"
        @load="onLoadtab()" width="100%" height="313px" frameborder="0"
        allowtransparency="true">
    </iframe>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      first_show: true,
      steamID: this.$store.state.user.steam,
    }
  },
  methods: {
    onLoadtab() {
      if (this.first_show) {
        this.first_show = false
      }
    },
  },
  components: {
    "z-progress": () => import('@/site/componets/progress'),
  }
}
</script>
